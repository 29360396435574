import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import MobiusStripPlaceholder from '../../assets/mobius-strip.webp';
import styled from '@emotion/styled';

function formatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
}

function timeAgo(publishDate) {
  const now = new Date();
  const published = new Date(publishDate * 1000);
  const diffInSeconds = Math.floor((now - published) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;
  const secondsInMonth = 30 * secondsInDay;
  const secondsInYear = 365 * secondsInDay;

  if (diffInSeconds < secondsInDay) {
    const hoursAgo = Math.floor(diffInSeconds / secondsInHour);
    const minutesAgo = Math.floor(diffInSeconds / secondsInMinute);
    return hoursAgo >= 1 ? `${hoursAgo} hours ago` : `${minutesAgo} minutes ago`;
  } else if (diffInSeconds < secondsInMonth) {
    const daysAgo = Math.floor(diffInSeconds / secondsInDay);
    return `${daysAgo} days ago`;
  } else if (diffInSeconds < secondsInYear) {
    const monthsAgo = Math.floor(diffInSeconds / secondsInMonth);
    return `${monthsAgo} months ago`;
  } else {
    return published.toLocaleDateString('en-US');
  }
}

function generateThumbnailUrl(thumbnail_url, setThumbnailImage) {
  if (thumbnail_url && thumbnail_url.startsWith('s3://')) {
    setThumbnailImage(MobiusStripPlaceholder);
    return;
  }
  const img = new Image();
  img.src = thumbnail_url; // Attempt to load the image with the provided source
  img.onload = () => setThumbnailImage(thumbnail_url);
  img.onerror = () => setThumbnailImage(MobiusStripPlaceholder);
}

const SummaryLoading = () => {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        width="86%"
        minWidth="86%"
        backgroundColor="#D9D9D9"
        marginBottom="4px"
        height="11px"
      ></Box>
      <Box
        width="90%"
        minwidth="90%"
        backgroundColor="#D9D9D9"
        marginBottom="4px"
        height="11px"
      ></Box>
      <Box width="74%" minWidth="74%" backgroundColor="#D9D9D9" height="11px"></Box>
    </Box>
  );
};

const XSocialMetadata = ({ metadata, contentSummary }) => {
  const { post_publish_date, post_like_count, channel_name, channel_nickname, post_url } = metadata;
  const publishDate = new Date(post_publish_date * 1000).toLocaleDateString('en-US');

  return (
    <Box className="metadata-section">
      <Typography fontSize="16px" fontWeight="600" marginBottom="8px" color="#263228">
        Content Summary
      </Typography>
      <Box sx={{ paddingBottom: '10px' }}>
        {contentSummary ? (
          <Typography fontSize={'14px'} color={'#78909C'}>
            {contentSummary}
          </Typography>
        ) : (
          <SummaryLoading />
        )}
      </Box>
      <Divider />
      <Box sx={{ paddingTop: '10px' }}>
        <Typography fontSize={'16px'} fontWeight={600} color={"#263228"}>
          {channel_name}
        </Typography>
        <Typography fontSize={'14px'} fontWeight={400} color={'#78909C'}>
          {channel_nickname}
        </Typography>
        <Typography fontSize={'14px'} fontWeight={400} color={'#78909C'} sx={{ paddingTop: '2px' }}>
          <UnstyledLink to={post_url} target="_blank" rel="noopener noreferrer">
            {post_url}
          </UnstyledLink>
        </Typography>
        <Typography fontSize={'14px'} fontWeight={400} color={'#78909C'} sx={{ paddingTop: '2px' }}>
          {formatNumber(post_like_count)} likes • {publishDate}
        </Typography>
      </Box>
    </Box>
  );
};

const TikTokMetadata = ({ metadata, videoSummary }) => {
  const {
    video_title,
    video_views,
    video_likes,
    video_publish_date,
    channel_name,
    channel_nickname,
    thumbnail_url,
    url,
  } = metadata;

  const publishDate = timeAgo(video_publish_date);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  useEffect(() => {
    generateThumbnailUrl(thumbnail_url, setThumbnailImage);
  }, [thumbnail_url])

  return (
    <Box className="metadata-section">
      <Typography fontSize="16px" fontWeight="600" marginBottom="8px" color="#263228">
        Content Summary
      </Typography>
      <Box>
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            paddingBottom: '15px',
          }}
        >
          <UnstyledLink to={url} target="_blank" rel="noopener noreferrer">
            <img
              src={thumbnailImage}
              alt="thumbnail"
              style={{
                maxWidth: '100px',
                objectFit: 'cover',
                borderRadius: '5px',
                marginRight: '15px',
              }}
            ></img>
          </UnstyledLink>
          <Box>
            <Typography fontSize={'16px'} fontWeight={600} lineHeight={'22.4px'} color="#263228">
              <UnstyledLink to={url} target="_blank" rel="noopener noreferrer">
                {video_title}
              </UnstyledLink>
            </Typography>
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              color={'#78909C'}
              sx={{ paddingTop: '2px' }}
            >
              {formatNumber(video_views)} views • {formatNumber(video_likes)} likes • {publishDate}
            </Typography>
            <Typography
              fontSize={'12px'}
              fontWeight={400}
              color={'#78909C'}
              sx={{ paddingTop: '2px' }}
            >
              <UnstyledLink href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </UnstyledLink>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider></Divider>
      <Box sx={{ padding: '10px 0' }}>
        {videoSummary ? (
          <Typography fontSize={'14px'} color={'#78909C'}>
            {videoSummary}
          </Typography>
        ) : (
          <SummaryLoading />
        )}
      </Box>
      <Divider />
      <Box sx={{ paddingTop: '10px' }}>
        <Typography fontSize={'16px'} fontWeight={600} color={"#263228"}>
          {channel_name}
        </Typography>
        <Typography fontSize={'14px'} fontWeight={400} color={'#78909C'}>
          {channel_nickname}
        </Typography>
      </Box>
    </Box>
  );
};

const YouTubeMetadata = ({ metadata, videoSummary }) => {
  const {
    thumbnail_url,
    video_title,
    video_views,
    video_publish_date,
    channel_info,
    url,
  } = metadata;

  const publishDate = timeAgo(video_publish_date);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  useEffect(() => {
    generateThumbnailUrl(thumbnail_url, setThumbnailImage);
  }, [thumbnail_url])

  return (
    <Box className="metadata-section">
      <Typography fontSize="16px" fontWeight="600" marginBottom="8px" color="#263228">
        Content Summary
      </Typography>
      <Box>
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            paddingBottom: '15px',
          }}
        >
          <UnstyledLink to={url} target="_blank" rel="noopener noreferrer">
            <img
              src={thumbnailImage}
              alt="thumbnail"
              style={{
                maxWidth: '100px',
                objectFit: 'cover',
                borderRadius: '5px',
                marginRight: '15px',
              }}
            ></img>
          </UnstyledLink>
          <Box>
            <Typography fontSize={'16px'} fontWeight={600} lineHeight={'22.4px'} color="#263228">
              <UnstyledLink to={url} target="_blank" rel="noopener noreferrer">
                {video_title}
              </UnstyledLink>
            </Typography>
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              color={'#78909C'}
              sx={{ paddingTop: '2px' }}
            >
              {formatNumber(video_views)} views • {publishDate}
            </Typography>
            <Typography
              fontSize={'12px'}
              fontWeight={400}
              color={'#78909C'}
              sx={{ paddingTop: '2px' }}
            >
              <UnstyledLink to={url} target="_blank" rel="noopener noreferrer">
                {url}
              </UnstyledLink>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider></Divider>
      <Box sx={{ padding: '10px 0' }}>
        {videoSummary ? (
          <Typography fontSize={'14px'} color={'#78909C'}>
            {videoSummary}
          </Typography>
        ) : (
          <SummaryLoading />
        )}
      </Box>
      <Divider />
      <Box sx={{ paddingTop: '10px' }}>
        <Typography fontSize={'16px'} fontWeight={600} color={"#263228"}>
          {channel_info?.channel_name}
        </Typography>
        <Typography fontSize={'14px'} fontWeight={400} color={'#78909C'}>
          {formatNumber(channel_info?.subscriber_count)} Subscribers • {channel_info?.video_count}{' '}
          Videos
        </Typography>
      </Box>
    </Box>
  );
};

const MiscMetadataLess = ({ summary }) => {
  return (
    <div className="metadata-section">
      <Typography fontSize="16px" fontWeight="600" marginBottom="8px" color="#263228">
        Content Summary
      </Typography>
      <Box>
        {summary ? (
          <Typography fontSize={'14px'} color={'#78909C'}>
            {summary}
          </Typography>
        ) : (
          <SummaryLoading />
        )}
      </Box>
    </div>
  );
};

const IrisTVMetadata = ({ metadata, videoSummary }) => {
  return (
    <div className="metadata-section">
      <Typography fontSize="16px" fontWeight="600" marginBottom="8px" color="#263228">
        Content Summary
      </Typography>
      <Box>
        {videoSummary ? (
          <Typography fontSize={'14px'} color={'#78909C'}>
            {videoSummary}
          </Typography>
        ) : (
          <SummaryLoading />
        )}
      </Box>
    </div>
  );
};

const OmnyMetadata = ({ metadata, videoSummary }) => {
  return (
    <div className="metadata-section">
      <Typography fontSize="16px" fontWeight="600" marginBottom="8px" color="#263228">
        Content Summary
      </Typography>
      <Box>
        {metadata?.channel_name && (
          <Typography fontSize={'16px'} fontWeight={600} color={"#263228"}>
            {metadata?.channel_name}
          </Typography>)}
        {videoSummary ? (
          <Typography fontSize={'14px'} color={'#78909C'}>
            {videoSummary}
          </Typography>
        ) : (
          <SummaryLoading />
        )}
        {metadata?.url && (
          <Typography fontSize={'14px'} fontWeight={400} color={'#78909C'} marginTop="8px">
            <UnstyledLink to={metadata?.url} target="_blank" rel="noopener noreferrer">
              {metadata?.url}
            </UnstyledLink>
          </Typography>
        )}
      </Box>
    </div>
  );
};

const ArticleMetadata = ({ metadata, articleSummary }) => {
  const {
    article_title,
    article_author,
    article_published_date,
    thumbnail_url,
    url,
  } = metadata;
  const [publishDate, setPublishDate] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  useEffect(() => {
    generateThumbnailUrl(thumbnail_url, setThumbnailImage);
  }, [thumbnail_url])

  useEffect(() => {
    if (article_published_date) {
      setPublishDate(new Date(article_published_date * 1000).toLocaleDateString('en-US'));
    }
  }, [article_published_date]);

  return (
    <>
      {article_title && (
        <div className="metadata-section">
          <Typography fontSize="16px" fontWeight="600" marginBottom="8px" color="#263228">
            Content Summary
          </Typography>
          <Box>
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                paddingBottom: '15px',
              }}
            >
              <UnstyledLink to={url} target="_blank" rel="noopener noreferrer">
                <img
                  src={thumbnailImage}
                  alt="thumbnail"
                  style={{
                    maxWidth: '100px',
                    objectFit: 'cover',
                    borderRadius: '5px',
                    marginRight: '15px',
                    display: 'block',
                  }}
                ></img>
              </UnstyledLink>
              <Box>
                <Typography fontSize={'16px'} fontWeight={600} lineHeight={'22.4px'} color="#263228">
                  <UnstyledLink to={url} target="_blank" rel="noopener noreferrer">
                    {article_title}
                  </UnstyledLink>
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  color={'#78909C'}
                  sx={{ paddingTop: '2px' }}
                >
                  {publishDate}
                </Typography>
                <Typography
                  fontSize={'12px'}
                  fontWeight={400}
                  color={'#78909C'}
                  sx={{ paddingTop: '2px' }}
                >
                  <UnstyledLink to={url} target="_blank" rel="noopener noreferrer">
                    {url}
                  </UnstyledLink>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ padding: '10px 0' }}>
            {articleSummary ? (
              <Typography fontSize={'14px'} color={'#78909C'}>
                {articleSummary}
              </Typography>
            ) : (
              <SummaryLoading />
            )}
          </Box>
          {article_author && article_author.length > 0 && (
            <>
              <Divider />
              <Box sx={{ paddingTop: '10px' }}>
                <Typography fontSize={'16px'} fontWeight={600} color={"#263228"}>
                  {article_author.join(', ')}
                </Typography>
              </Box>
            </>
          )}
        </div>
      )}
    </>
  );
};

const MetadataSummaryContent = ({ metadata, summary, source }) => {
  switch (source) {
    case 'youtube':
      return <YouTubeMetadata metadata={metadata} videoSummary={summary} />;
    case 'tiktok':
      return <TikTokMetadata metadata={metadata} videoSummary={summary} />;
    case 'article':
      return <ArticleMetadata metadata={metadata} articleSummary={summary} />;
    case 'xsocial':
      return <XSocialMetadata metadata={metadata} contentSummary={summary} />;
    case 'iris_tv':
      return <IrisTVMetadata metadata={metadata} videoSummary={summary} />;
    case 'cloud_file_storage':
      return <MiscMetadataLess summary={summary} />;
    case 'omny':
      return <OmnyMetadata metadata={metadata} videoSummary={summary} />;
    default:
      return <div className="metadata-section">No metadata available for this source.</div>;
  }
};

const MetadataSummary = ({ metadata, summary, source }) => {
  return (
    <Box
      className="summary-container"
      bgcolor="white"
      borderRadius="6px"
      boxShadow="0 4px 2px -2px #CFD8DC"
      padding="16px"
    >
      <MetadataSummaryContent metadata={metadata} summary={summary} source={source} />
    </Box>
  );
};

export default MetadataSummary;

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
