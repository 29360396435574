import React, { useCallback, useState } from 'react';
import { Close } from '@mui/icons-material';
import { Button, InputBase } from '@mui/material';
import styled from '@emotion/styled';
import { getRandomSuggestions } from 'Helpers/searchSuggestions';

const ContentSearchBar: React.FC<{
  onClear: () => void;
  onSearch: (search: string) => void;
  onSuggestionClick: (suggestion: string) => void;
  setValue: (value: string) => void;
  value?: string;
}> = ({ onClear, onSearch, onSuggestionClick: _onSuggestionClick, setValue, value }) => {
  const [suggestions, setSuggestions] = useState<string[]>(getRandomSuggestions(3));

  const onSearchLocal = useCallback((search: string) => {
    setSuggestions(getRandomSuggestions(3));
    onSearch(search);
  }, [onSearch]);

  const onKeyDown = useCallback((key: string) => {
    if (key !== 'Enter' || value.length === 0) return;
    onSearchLocal(value);
    (document.activeElement as HTMLElement).blur();
  }, [value, onSearchLocal]);

  const onSuggestionClick = useCallback((suggestion: string) => {
    setSuggestions(getRandomSuggestions(3));
    _onSuggestionClick(suggestion);
  }, [_onSuggestionClick]);

  return (
    <SearchBarContainer>
      <SearchRowContainer>
        <SearchInputContainer>
          {value && (
            <CloseIconWrapper>
              <Close onClick={onClear} />
            </CloseIconWrapper>
          )}
          <StyledInputBase
            placeholder="Search for content…"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => onKeyDown(e.key)}
            inputProps={{ 'aria-label': 'search' }}
          />
        </SearchInputContainer>
        <Button
          variant="contained"
          size="small"
          onClick={() => onSearchLocal(value)}
          sx={{
            backgroundColor: '#263238',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#263238',
            },
          }}
        >
          Search
        </Button>
      </SearchRowContainer>
      <SuggestionsContainer>
        Try these:
        <SuggestionsList>
          {suggestions.map((suggestion) => (
            <SuggestionItem key={suggestion}>
              <SuggestionLink onClick={() => onSuggestionClick(suggestion)}>
                {suggestion}
              </SuggestionLink>
            </SuggestionItem>
          ))}
          <RandomSuggestionItem>
            (<SuggestionLink onClick={() => onSuggestionClick(getRandomSuggestions(1)[0])}>
              Random
            </SuggestionLink>)
          </RandomSuggestionItem>
        </SuggestionsList>
      </SuggestionsContainer>
    </SearchBarContainer>
  );
};

export default ContentSearchBar;

const SuggestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  font-size: 12px;
`;

const SuggestionsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0; 
`;

const SuggestionItem = styled.li``;

const RandomSuggestionItem = styled(SuggestionItem)`
  margin-left: 8px;
`;

const SuggestionLink = styled.span`
  cursor: pointer;
  color: #263238;
  text-decoration: underline;
`;

const SearchBarContainer = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0 1em 1em 1em;
`;

const SearchRowContainer = styled.div`
  display: flex;
  gap: 6px;
  min-height: 40px;
`;

const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CloseIconWrapper = styled.div`
  color: #37474f;
  position: absolute;
  right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
`;

const StyledInputBase = styled(InputBase)`
  color: #263238;
  background-color: white;
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 6px;
  padding-right: 1.5em;
  & .MuiInputBase-input {
    padding: 8px;
    font-weight: 400;
    color: gray;
    opacity: 1;
  }
`;
