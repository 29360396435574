import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import styled from '@emotion/styled';

import { Video } from 'api';
import CardFlip from './CardFlip';
import { Box } from '@mui/material';

interface GalleryProps {
  hasMore: boolean;
  isInitialLoading: boolean;
  isLoading: boolean;
  paginationKey: string | null;
  videos: Video[];
  fetchVideos: (paginationKey: string | null) => void;
  onCardClick: (video: Video) => void;
  limit?: number;
}

const Gallery: React.FC<GalleryProps> = ({
  hasMore,
  isInitialLoading,
  isLoading,
  paginationKey,
  videos,
  fetchVideos,
  onCardClick,
  limit = 60,
}) => {
  return (
    <GalleryContainer id="gallery-container">
      {isLoading && !videos.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />
      )}
      <InfiniteScroll
        style={{ overflowX: 'hidden', alignItems: 'center', justifyContent: 'center' }}
        dataLength={videos.length}
        next={() => fetchVideos(paginationKey)}
        hasMore={hasMore && videos.length < limit}
        loader={<></>}
        scrollThreshold={0.2}
      >
        {!isInitialLoading && (
          <ResponsiveMasonryStyled columnsCountBreakPoints={BREAKPOINTS}>
            <Masonry gutter="5px">
              {videos.map((video) => (
                <CardWrapper key={video.video_id} onClick={() => onCardClick(video)}>
                  <Card video={video} />
                </CardWrapper>
              ))}
            </Masonry>
          </ResponsiveMasonryStyled>
        )}
      </InfiniteScroll>
    </GalleryContainer>
  );
};

export default Gallery;

const CardWrapper = styled.div`
  cursor: pointer;
`;

const Card = ({ video }: { video: Video }) => {
  if (video.source === 'youtube') {
    return (
      <CardFlip
        imageSrc={video.thumbnail_url}
        mainContent={video.video_title}
        title={video.channel_name}
        backContent={video.video_title}
      />
    );
  } else if (video.source === 'tiktok') {
    return (
      <CardFlip
        imageSrc={video.thumbnail_url}
        mainContent={video.video_title}
        title={video.channel_name}
        backContent={video.video_title}
      />
    );
  } else if (video.source === 'iris_tv') {
    return (
      <CardFlip
        imageSrc={video.thumbnail_url}
        mainContent={video.video_title}
        title="CTV"
        backContent={video.video_title}
      />
    );
  } else if (video.source === 'omny') {
    return (
      <CardFlip
        imageSrc={video.thumbnail_url}
        mainContent={video.podcast_title}
        title={video.channel_name}
        backContent={video.podcast_title}
      />
    );
  } else {
    return (
      <CardFlip
        imageSrc={video.thumbnail_url}
        mainContent={video.article_title}
        title={video.channel_id}
        backContent={video.article_title}
      />
    );
  }
};

const GalleryContainer = styled.div`
  width: 100%;
`;

const ResponsiveMasonryStyled = styled(ResponsiveMasonry)`
  margin: 0 auto;
  @media (min-width: 1500px) {
    margin-right: 10%;
    margin-left: 10%;
  }
`;

const EndMessage = styled.h4`
  text-align: center;
  font-size: 1em;
  color: #000;
  opacity: 0.5;
  font-weight: normal;
`;

const BREAKPOINTS = {
  25: 1,
  200: 2,
  300: 3,
  328: 4,
  490: 4,
  675: 5,
  850: 6,
  1000: 6,
  1400: 6,
  1850: 6,
  2000: 6,
  2675: 9,
  3000: 12,
  3250: 13,
};
