import styled from '@emotion/styled';
import Page from 'Components/Page';
import React from 'react';

export default function PrivacyPage() {
  const ContactEmail = () => {
    return <a href="mailto:contact@themobian.com">contact@themobian.com</a>
  };

  return (
    <Page greyBackground>
      <CentredLayout>
        <Title>Mobian Terms of Service</Title>
        <p>Effective date: September 15, 2023</p>
        <p>Welcome to Mobian. Please read on to learn the rules and restrictions that govern your use of our website(s)
          and any website that links to these Terms (individually and collectively, the &ldquo;Services&rdquo;). If you
          have any questions, comments, or concerns regarding these terms or the Services, please contact us at {ContactEmail()}.</p>
        <p>These Terms of Use (the &ldquo;Terms&rdquo;) are a binding contract between you and <b>Mobian LLC</b>{' '}
          (&ldquo;Mobian&rdquo;, &ldquo;we&rdquo; and &ldquo;us&rdquo;). Your use of the Services in any way means that
          you
          agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms
          include the provisions in this document as well as those in the Privacy Policy located at <a href="/privacy-policy">themobian.com/privacy-policy</a> and any other relevant policies. <b>Your use of or
            participation in certain Services may also be subject to additional policies, rules and/or conditions
            (&ldquo;Additional Terms&rdquo;), which are incorporated herein by reference, and you understand and agree
            that by using or participating in any such Services, you agree to also comply with these Additional Terms.</b>
        </p>
        <p><b>Please read these Terms carefully.</b> They cover important information about Services provided to you.
          <b>These Terms include information about <a href="#future-changes">future changes to these Terms</a>, <a
            href="#limited-liability">limitations of liability</a>, <a href="#arbitration-agreement">a class action
              waiver and resolution of disputes by arbitration instead of in court</a>. PLEASE NOTE THAT YOUR USE OF AND
            ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU
            MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</b>
        </p>
        <p><b>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</b> EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE <a
          href="#arbitration-agreement">ARBITRATION AGREEMENT SECTION BELOW</a>, YOU AGREE THAT DISPUTES BETWEEN YOU AND
          US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
          LAWSUIT OR CLASS-WIDE ARBITRATION.</p>

        <h2 id='future-changes'>Will these Terms ever change?</h2>
        <p>We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We
          reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at{' '}
          <a href="/terms.html">themobian.com/terms</a> and/or notify you by some other means.
        </p>
        <p>If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that means you will no
          longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective,
          that means you agree to all of the changes.</p>
        <p>Except for changes by us as described here, no other amendment or modification of these Terms will be effective
          unless in writing and signed by both you and us.</p>

        <h2>What about my privacy?</h2>
        <p>For the current Mobian Privacy Policy, please click here: <a href="/privacy-policy">themobian.com/privacy-policy</a>.
        </p>

        <h3>Children&rsquo;s Online Privacy Protection Act</h3>
        <p>The Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;) requires that online service providers
          obtain parental consent before they knowingly collect personally identifiable information online from children
          who are under 16 years of age. We do not knowingly collect or solicit personally identifiable information from
          children under 16 years of age; if you are a child under 16 years of age, please do not attempt to register for
          or otherwise use the Services or send us any personal information. If we learn we have collected personal
          information from a child under 16 years of age, we will delete that information as quickly as possible. If you
          believe that a child under 16 years of age may have provided us personal information, please contact us at{' '}
          {ContactEmail()}.</p>

        <h2>What are the basics of using the Services?</h2>
        <p>You may provide us with certain information or data, such as your contact information. You promise to provide
          us with accurate, complete, and updated information about yourself. You may not provide a name that you do not
          have the right to use, or another person&rsquo;s name with the intent to impersonate that person.</p>
        <p>You represent and warrant that you are an individual of legal age to form a binding contract. If you&rsquo;re
          agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are
          authorized to agree to these Terms on that organization&rsquo;s or entity&rsquo;s behalf and bind them to these
          Terms (in which case, the references to &ldquo;you&rdquo; and &ldquo;your&rdquo; in these Terms, except for in
          this sentence, refer to that organization or entity).</p>
        <p>You will only use the Services for your own internal use, and not on behalf of or for the benefit of any third
          party, and only in a manner that complies with all laws that apply to you. If your use of the Services is
          prohibited by applicable laws, then you aren&rsquo;t authorized to use the Services. We can&rsquo;t and
          won&rsquo;t be responsible for your using the Services in a way that breaks the law.</p>

        <h2>Who is responsible for what I see and do on the Services?</h2>
        <p>The Services and the Content made available through the Service should not be referenced as a legal source, you
          should always confirm any information that you locate or review through the Services from the original source
          materials. Any information or Content publicly posted or privately transmitted through the Services is the sole
          responsibility of the person or entity from whom such Content originated, and you access all such information
          and Content at your own risk, and we aren&rsquo;t liable for the accuracy or quality of the Content available
          through the Services or any errors or omissions in that information or Content or for any damages or loss you
          might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may
          interpret and use the Content or what actions you may take as a result of having been exposed to the Content,
          and you hereby release us from all liability for you having acquired or not acquired Content through the
          Services. We can&rsquo;t guarantee the identity of any users with whom you interact in using the Services and
          are not responsible for which users gain access to the Services.</p>
        <p>You are responsible for all Content you contribute, in any manner, to the Services, and you represent and
          warrant you have all rights necessary to do so, in the manner in which you contribute it.</p>
        <p>The Services contain links, integrations and/or other connections to third-party websites, applications,
          services and/or resources that are not owned or controlled by Mobian (&ldquo;Third-Party Services&rdquo;). We
          are not responsible for the operation of any Third-Party Services, nor the availability or operation of the
          Services to the extent such availability and operation is dependent upon Third-Party Services. When you access
          third-party websites or use any third-party services, you accept that there are risks in doing so, and that
          Mobian is not responsible for such risks.</p>
        <p>Mobian does not make any representations or warranties with respect to any Third-Party Services. Mobian has no
          control over, and assumes no responsibility for, the content, accuracy or completeness, privacy policies, or
          practices of or opinions expressed in or originating from any Third-Party Services or by any third party that
          you interact with through the Services. In addition, Mobian will not and cannot monitor, verify, censor or edit
          any information or Content provided or made available any Third-Party Service. We encourage you to be aware when
          you leave the Services and to read the terms and conditions and privacy policy of each third-party website or
          service that you visit or utilize. By using the Services, you release and hold us harmless from any and all
          liability arising from your use of any third-party website or service.</p>
        <p>If there is a dispute between participants on this site or Services, or between users and any third party, you
          agree that Mobian is under no obligation to become involved. In the event that you have a dispute with one or
          more other users or third parties, you release Mobian, its directors, officers, employees, agents, and
          successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or
          unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our
          Services. You shall and hereby do waive California Civil Code Section 1542 or any similar law of any
          jurisdiction, which says in substance: &ldquo;A general release does not extend to claims that the creditor or
          releasing party does not know or suspect to exist in his or her favor at the time of executing the release and
          that, if known by him or her, would have materially affected his or her settlement with the debtor or released
          party.&rdquo;</p>

        <h2>What are my rights in the Services?</h2>
        <p>The materials displayed or performed or available on or through the Services, including, but not limited to,
          text, graphics, data, articles, maps and so forth (all of the foregoing, the &ldquo;Content&rdquo;) may be
          protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices,
          trademark rules, information, and restrictions contained in any Content you access through the Services, and you
          won&rsquo;t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload,
          display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i)
          without the prior consent of the owner of that Content or (ii) in a way that violates someone else&rsquo;s
          (including Mobian&#39;s) rights.</p>
        <p>Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and
          non-transferable license to use (i.e., to download and display locally) our Content solely for purposes of using
          the Services and any third party Content within the scope of any rights granted to such Content by the
          applicable third party. Use, reproduction, modification, distribution or storage of any Content for any other
          purpose is expressly prohibited without prior written permission from us. You understand that Mobian owns the
          Services. You won&rsquo;t modify, publish, transmit, participate in the transfer or sale of, reproduce (except
          as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the
          Services. The Services may allow you to copy or download certain Content, but please remember that even where
          these functionalities exist, all the restrictions in this section still apply.</p>

        <h2>Are there restrictions in how I can use the Services?</h2>
        <p>You represent, warrant, and agree that you will not provide or contribute anything, including any Content (as
          that term is defined below), to the Services, or otherwise use or interact with the Services, in a manner that:
        </p>
        <ol type="a">
          <li>
            <p>infringes or violates the intellectual property rights or any other rights of anyone else (including
              Mobian);</p>
          </li>
          <li>
            <p>violates any law or regulation, including, without limitation, any applicable export control laws, privacy
              laws or any other purpose not reasonably intended by Mobian;</p>
          </li>
          <li>
            <p>is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise
              objectionable;</p>
          </li>
          <li>
            <p>targets companies or organizations;</p>
          </li>
          <li>
            <p>attempts, in any manner, to obtain the password, account, or other security information from any other
              user;</p>
          </li>
          <li>
            <p>violates the security of any computer network, or cracks any passwords or security encryption codes;</p>
          </li>
          <li>
            <p>runs Maillist, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services, or any processes
              that run or are activated while you are not logged into the Services, or that otherwise interfere with the
              proper working of the Services (including by placing an unreasonable load on the Services&rsquo;
              infrastructure);</p>
          </li>
          <li>
            <p>&ldquo;crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data, or portion of or
              relating to the Services or Content (through use of manual or automated means);</p>
          </li>
          <li>
            <p>copies or stores any significant portion of the Content; or</p>
          </li>
          <li>
            <p>decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or
              information of or relating to the Services.</p>
          </li>
        </ol>
        <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>

        <h2>Will Mobian ever change the Services?</h2>
        <p>We&rsquo;re always trying to improve our Services, so they may change over time. We may suspend or discontinue
          any part of the Services, or we may introduce new features or impose limits on certain features or restrict
          access to parts or all of the Services. We&rsquo;ll try to give you notice when we make a material change to the
          Services that would adversely affect you, but this isn&rsquo;t always practical. We reserve the right to remove
          any Content from the Services at any time, for any reason, in our sole discretion, and without notice.</p>

        <h2>What if I want to stop using the Services?</h2>
        <p>You&rsquo;re free to do that at any time; please refer to our Privacy Policy located at
          www.themobian.com/privacy, as well as the licenses above, to understand how we treat information you provide to
          us
          after you have stopped using our Services.</p>
        <p>Mobian is also free to terminate (or suspend access to) your use of the Services for any reason in our
          discretion, including your breach of these Terms. Mobian has the sole right to decide whether you are in
          violation of any of the restrictions set forth in these Terms.</p>
        <p>Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way
          of example, all of the following will survive termination: any obligation you have to indemnify us, any
          limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding
          disputes between us, including without limitation the arbitration agreement.</p>

        <h2>What else do I need to know?</h2>
        <p><em><u>Warranty Disclaimer.</u></em> Mobian and its licensors, suppliers, partners, parent, subsidiaries or
          affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract
          employees, representatives and agents, and each of their respective successors and assigns (Mobian and all such
          parties together, the &ldquo;Mobian Parties&rdquo;) make no representations or warranties concerning the
          Services, including without limitation regarding any Content contained in or accessed through the Services, and
          the Mobian Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or
          decency of material contained in or accessed through the Services or any claims, actions, suits procedures,
          costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in,
          the Services. The Mobian Parties make no representations or warranties regarding suggestions or recommendations
          of services or products offered or purchased through or in connection with the Services. THE SERVICES AND
          CONTENT ARE PROVIDED BY MOBIAN (AND ITS LICENSORS AND SUPPLIERS) ON AN &ldquo;AS-IS&rdquo; BASIS, WITHOUT
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE
          UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
          ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
        <p id="limited-liability"><em><u>Limitation of Liability.</u></em> TO THE FULLEST EXTENT ALLOWED BY
          APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT,
          STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE MOBIAN PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A)
          ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST
          PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER
          FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN
          EXCESS OF ONE-HUNDRED ($100) DOLLARS OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND
          EXCLUSIONS MAY NOT APPLY TO YOU.</p>
        <p><em><u>Indemnity.</u></em> To the fullest extent allowed by law, you agree to indemnify and hold the Mobian
          Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses
          and expenses (including attorneys&rsquo; fees) arising from or in any way related to any claims relating to (a)
          your use of the Services, and (b) your violation of these Terms.</p>
        <p><em><u>Assignment.</u></em> You may not assign, delegate or transfer these Terms or your rights or obligations
          hereunder, or your Services account, in any way (by operation of law or otherwise) without Mobian&#39;s prior
          written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without
          consent.</p>
        <p><em><u>Choice of Law.</u></em> These Terms are governed by and will be construed under the Federal Arbitration
          Act, applicable federal law, and the laws of the State of New York, without regard to the conflicts of laws
          provisions thereof.</p>
        <p id="arbitration-agreement"><em><u>Arbitration Agreement</u></em>. Please read the following
          ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with Mobian and
          limits the manner in which you can seek relief from Mobian. Both you and Mobian acknowledge and agree that for
          the purposes of any dispute arising out of or relating to the subject matter of these Terms, Mobian&#39;s officers,
          directors, employees and independent contractors (&ldquo;Personnel&rdquo;) are third-party beneficiaries of
          these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to
          have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</p>
        <p><em>(a) Arbitration Rules; Applicability of Arbitration Agreement.</em> The parties shall use their best
          efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter
          of these Terms directly through good-faith negotiations, which shall be a precondition to either party
          initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding
          arbitration in New York, New York. The arbitration will proceed in the English language, in accordance with the
          JAMS Streamlined Arbitration Rules and Procedures (the &ldquo;Rules&rdquo;) then in effect, by one commercial
          arbitrator with substantial experience in resolving intellectual property and commercial contract disputes. The
          arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules.
          Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.</p>
        <p><em>(b) Costs of Arbitration.</em> The Rules will govern payment of all arbitration fees. Mobian will pay all
          arbitration fees for claims less than seventy-five thousand ($75,000) dollars. Mobian will not seek its
          attorneys&rsquo; fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.
        </p>
        <p><em>(c) Small Claims Court; Infringement.</em> Either you or Mobian may assert claims, if they qualify, in
          small claims court in New York, New York or any United States county where you live or work. Furthermore,
          notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue
          injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the
          actual or threatened infringement, misappropriation or violation of a party&#39;s copyrights, trademarks, trade
          secrets, patents or other intellectual property rights.</p>
        <p><em>(d) Waiver of Jury Trial.</em> YOU AND MOBIAN WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT
          AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Mobian are instead choosing to have claims and disputes
          resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than
          rules applicable in court and are subject to very limited review by a court. In any litigation between you and
          Mobian over whether to vacate or enforce an arbitration award, YOU AND MOBIAN WAIVE ALL RIGHTS TO A JURY TRIAL,
          and elect instead to have the dispute be resolved by a judge.</p>
        <p><em>(e) Waiver of Class or Consolidated Actions.</em> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS
          ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF
          MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER
          CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable,
          neither you nor Mobian is entitled to arbitration; instead all claims and disputes will be resolved in a court
          as set forth in (g) below.</p>
        <p><em>(f) Opt-out.</em> You have the right to opt out of the provisions of this Section by sending written notice
          of your decision to opt out to the following address: 410 E Upland Road, Ithaca, New York 14850 postmarked
          within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address,
          (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that
          you want to opt out of these Terms&rsquo; arbitration agreement.</p>
        <p><em>(g) Exclusive Venue.</em> If you send the opt-out notice in (f), and/or in any circumstances where the
          foregoing arbitration agreement permits either you or Mobian to litigate any dispute arising out of or relating
          to the subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either
          party, and both you and Mobian agree that any judicial proceeding (other than small claims actions) will be
          brought in the state or federal courts located in, respectively, New York, New York, or the federal district in
          which that county falls.</p>
        <p><em>(h) Severability.</em> If the prohibition against class actions and other claims brought on behalf of third
          parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration
          Agreement section will be null and void. This arbitration agreement will survive the termination of your
          relationship with Mobian.</p>
        <p><em><u>Miscellaneous.</u></em> The failure of either you or us to exercise, in any way, any right herein shall
          not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be
          unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that
          these Terms shall otherwise remain in full force and effect and enforceable. You and Mobian agree that these
          Terms are the complete and exclusive statement of the mutual understanding between you and Mobian, and that
          these Terms supersede and cancel all previous written and oral agreements, communications and other
          understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not
          an employee, agent, partner, or joint venture of Mobian, and you do not have any authority of any kind to bind
          Mobian in any respect whatsoever.</p>
        <p>Except as expressly set forth in the section above regarding the arbitration agreement, you and Mobian agree
          there are no third-party beneficiaries intended under these Terms.</p>
      </CentredLayout>
    </Page>
  );
}

const Title = styled.h1`
  text-align: center;
`;

const CentredLayout = styled.div`
  padding: 2rem 0 4rem;
  text-align: left;
  max-width: 1280px;
  width: 100%;
`;