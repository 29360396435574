import { useNavigate, useLocation, Link } from 'react-router-dom';
import React, { useContext, useEffect, Dispatch, SetStateAction, useState } from 'react';
import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import Env from 'Helpers/environment';
import { FiltersContext, UrlContext } from 'context';
import MobianLogo from 'logo.svg';
import SearchBar from './SearchBar';

interface PageProps {
  children: React.ReactNode;
  greyBackground?: boolean;
  customBackground?: boolean;
  customBackgroundColor?: string;
  showSearch?: boolean;
  title?: string;
}

const Page: React.FC<PageProps> = ({
  children,
  greyBackground = false,
  customBackground = false,
  customBackgroundColor = '',
  showSearch = false,
  title = 'Mobian',
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUrl } = useContext(UrlContext);
  const [isNavMenuOpen, setIsNavMenuOpen] = useState<boolean>(false);
  const {
    setRedirection,
    clearFilters,
  } = useContext(FiltersContext);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (location.state && location.state.from) {
      setRedirection(location.state.from);
    }
  }, [location.state, setRedirection]);

  useEffect(() => {
    if (window.innerWidth > 800) {
      document.body.style.overflow = 'unset';
      return;
    }
    if (isNavMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isNavMenuOpen]);

  const handleClearAllFilters = () => {
    clearFilters();
  };

  const handleLogoClick = () => {
    handleClearAllFilters();
    setUrl('');
    navigate('/');
  };

  return (
    <PageContainer
      greyBackground={greyBackground}
      customBackground={customBackground}
      customBackgroundColor={customBackgroundColor}
    >
      <HeaderContainer
        greyBackground={greyBackground}
        customBackground={customBackground}
        customBackgroundColor={customBackgroundColor}
      >
        <Header>
          <Logo src={MobianLogo} alt="Mobian logo" onClick={handleLogoClick} />
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsNavMenuOpen(!isNavMenuOpen)}
          >
            <MenuIcon />
          </IconButton> */}
        </Header>
        {/* <NavMenu isOpen={isNavMenuOpen} setIsOpen={setIsNavMenuOpen} /> */}
        {showSearch && <SearchBar handleClearAllFilters={handleClearAllFilters} />}
      </HeaderContainer>
      <AppContainer>{children}</AppContainer>
      <FooterNavMenu />
    </PageContainer>
  );
};

export default Page;

const PageContainer = styled.div<{
  greyBackground: boolean;
  customBackground: boolean;
  customBackgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background-color: ${(props) =>
    props.customBackground
      ? props.customBackgroundColor
      : props.greyBackground
        ? '#f5f5f5'
        : 'white'};
  position: relative;
`;

const HeaderContainer = styled.header<{
  greyBackground: boolean;
  customBackground: boolean;
  customBackgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 0 var(--page-padding);
  ${(props) => props.greyBackground && `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);`}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1em 0.25em;
  height: var(--header-height);
  box-sizing: border-box;
`;

const Logo = styled.img`
  height: 18px;
  cursor: pointer;
`;

const AppContainer = styled.div`
  padding: 0 var(--page-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-width: 70%;
  flex: 1;
`;

const FooterNavMenu: React.FC = () => {
  return (
    <FooterNavList>
      <li>
        <NavLink to="/">
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="https://impact.themobian.com/login/">
          Impact Analytics
        </NavLink>
      </li>
      {Env.PersonaNavEnvs.includes(Env.CurrentEnv) && (
        <li>
          <NavLink to="/persona">
            Personas
          </NavLink>
        </li>
      )}
      <li>
        <NavLink to="/contact">
          Contact
        </NavLink>
      </li>
      <li>
        <NavLink to="/privacy">
          Privacy
        </NavLink>
      </li>
      <li>
        <NavLink to="/terms">
          Terms of Service
        </NavLink>
      </li>
    </FooterNavList>
  );
};

const FooterNavList = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 1em;
  justify-content: center;
  list-style: none;
  margin: 2em 0;
  padding: 0;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

// interface NavMenuProps {
//   isOpen: boolean;
//   setIsOpen: Dispatch<SetStateAction<boolean>>;
// }

// const NavMenu: React.FC<NavMenuProps> = ({ isOpen, setIsOpen }) => {
//   const isPersonaEnabled = Env.PersonaEnvs.includes(Env.CurrentEnv);
//   const navigate = useNavigate();

//   const handleNavClick = (path: string) => {
//     navigate(path);
//     setIsOpen(false);
//   };

//   return (
//     <NavMenuContainer isOpen={isOpen}>
//       <NavMenuInner isOpen={isOpen}>
//         <CloseButton onClick={() => setIsOpen(false)}>×</CloseButton>
//         <NavItem onClick={() => handleNavClick('/')}>Home</NavItem>
//         {isPersonaEnabled && <NavItem onClick={() => handleNavClick('/persona')}>Personas</NavItem>}
//         <NavItem>
//           <NavLink to="https://impact.themobian.com/login/">
//             Impact Analytics
//           </NavLink>
//         </NavItem>
//         <NavItem onClick={() => handleNavClick('/contact')}>Contact</NavItem>
//         <NavItem onClick={() => handleNavClick('/privacy')}>Privacy</NavItem>
//         <NavItem onClick={() => handleNavClick('/terms')}>Terms of Service</NavItem>
//       </NavMenuInner>
//     </NavMenuContainer>
//   );
// };

// const NavLink = styled(Link)`
//   text-decoration: none;
//   color: inherit;
// `;

// const NavItem = styled.div`
//   cursor: pointer;
//   padding: 0.5em 1em;
//   &:hover {
//     background-color: #f0f0f0;
//   }
//   @media (max-width: 800px) {
//     padding: 1em;
//     text-align: center;
//     width: 100%;
//   }
// `;

// const NavMenuContainer = styled.div<{ isOpen: boolean }>`
//   @media (max-width: 800px) {
//     background: white;
//     height: ${(props) => (props.isOpen ? '100vh' : '0')};
//     left: 0;
//     position: fixed;
//     top: var(--header-height);
//     width: 100%;
//     z-index: 100;
//   }
//   height: ${(props) => (props.isOpen ? 'var(--header-height)' : '0')};
//   overflow: hidden;
//   transition: height 0.3s ease-in-out;
// `;

// const NavMenuInner = styled.div<{ isOpen: boolean }>`
//   align-items: center;
//   display: flex;
//   gap: 1em;
//   justify-content: center;
//   opacity: ${(props) => (props.isOpen ? '1' : '0')};
//   transition: opacity 0.3s ease-in-out;
//   transition-delay: ${(props) => (props.isOpen ? '0.3s' : '0s')};
  
//   @media (max-width: 800px) {
//     align-items: center;
//     display: flex;
//     flex-direction: column;
//     font-size: 1.2em;
//     height: 100%;
//     justify-content: center;
//     padding: 2em;
//     box-sizing: border-box;
//     gap: 0;
//   }
// `;

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   cursor: pointer;
//   display: none;
//   font-size: 2em;
//   padding: 0.5em;
//   position: absolute;
//   right: 1em;
//   top: 0.5em;

//   @media (max-width: 800px) {
//     display: block;
//   }
// `;
