import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useContentSearch() {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const onSearch = useCallback((search: string) => {
    navigate(`/search?query=${encodeURIComponent(search)}`);
  }, [navigate]);

  return {
    onSearch,
    search,
    setSearch,
  };
}
