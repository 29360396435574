export const searchSuggestions = [
  'Adventure Travel',
  'Aquarium Maintenance',
  'Arctic Expeditions',
  'Art Tutorials',
  'ASMR Content',
  'Astrology Insights',
  'Award Show Highlights',
  'Baking Tutorials',
  'BBQ Recipes',
  'Becoming a Chef',
  'Beginner Workouts',
  'Best Dog Breeds for Families',
  'Book Recommendations',
  'Boosting Confidence',
  'Budget-Friendly Living',
  'Budgeting for Families',
  'Building Healthy Habits',
  'Business Headlines',
  'Career Change Advice',
  'Celebrity News',
  'Climate Change',
  'Cocktail Recipes',
  'Coffee Brewing Techniques',
  'Cryptocurrency 101',
  'DIY Home Projects',
  'Dream Interpretations',
  'Easy Recipes',
  'Eco-Friendly Living',
  'Educational Games for Kids',
  'Electric Vehicles',
  'Entrepreneurship Tips',
  'Evolution of Fashion',
  'Exploring the Amazon Rainforest',
  'Family Activities',
  'Family Travel Tips',
  'Fan Theories',
  'Fashion Trends',
  'Fitness Workouts',
  'Funny Animals',
  'Gadgets',
  'Gardening Advice',
  'Gardening for Beginners',
  'Healthy Recipes',
  'History of Aviation',
  'Holiday Travel Tips',
  'Holistic Health',
  'Home Decor Ideas',
  'Hot Air Balloon Rides',
  'How to Ace Interviews',
  'How to Cook',
  'How to Decorate Your Home',
  'How to Start a Roth IRA',
  'Human Psychology',
  'Industry Trends',
  'Interior Design Ideas',
  'Investing Basics',
  'Investing in Stocks',
  'Job Interview Questions',
  'Leadership Advice',
  'Living a Minimalist Life',
  'Lost Cities of the World',
  'Luxury Travel',
  'Magic Tricks',
  'Makeup Tutorials',
  'Meal Ideas for Kids',
  'Meal Prep Ideas',
  'Mental Health',
  'Minimalist Lifestyle',
  'Motivational Quotes',
  'Movie Reviews',
  'Music Trends',
  'New Year’s Resolutions',
  'Nostalgic TV Shows',
  'Parenting Advice',
  'Passive Income Ideas',
  'Planning a Financial Emergency Fund',
  'Pregnancy Tips',
  'Productivity Hacks',
  'Real Estate Crowdfunding',
  'Real Estate Trends',
  'Recycling Tips',
  'Renewable Energy',
  'Saving for Retirement',
  'Science Experiments',
  'Scuba Diving Hotspots',
  'Self-Care Practices',
  'Skincare Routines',
  'Sleep Improvement',
  'Sleep Training',
  'Smartphone Comparisons',
  'Space Exploration',
  'Sports News',
  'Stand-Up Comedy Specials',
  'Streaming Originals',
  'Street Food',
  'Strength Training',
  'Stress Management',
  'Study Tips',
  'Survival Skills',
  'Sustainable Fashion',
  'Teaching as a Career',
  'Technology Trends',
  'Thanksgiving Recipes',
  'Travel Guides',
  'Traveling with Pets',
  'TV Show Recommendations',
  'Unboxing Videos',
  'Understanding Credit Scores',
  'Upcoming Releases',
  'Vegan Meals',
  'Video Game Walkthroughs',
  'VR/AR',
  'Wedding Planning',
  'Weekend Getaways',
  'Weight Loss Programs',
  'Weird Historical Facts',
  'Wellness Tips',
  'Wildlife Conservation',
  'Wildlife Safaris',
  'Women Who Changed History',
  'World War II Secrets',
  'Yoga Routines',
  'Zero Waste Tips',
];

export const getRandomSuggestions = (count: number) => {
  const shuffled = searchSuggestions.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};
