import React from 'react';

import Page from 'Components/Page';
import BatchGallery from 'Components/Gallery/BatchGallery';

const BatchGalleryPage = () => {
  return (
    <Page showSearch>
      <BatchGallery />
    </Page>
  );
};

export default BatchGalleryPage;
