import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Link, useNavigate } from 'react-router-dom';

import api, { PersonaPagination, PersonaWebsite } from 'api';
import Page from 'Components/Page';
import CardFlip from 'Components/Gallery/CardFlip';
import { useObjectQueryState, QueryStringData } from 'Helpers/useQueryState';
import ContentSearchBar from 'Components/ContentSearchBar';

const PAGE_SIZE = 60;

interface GalleryState {
  websites: PersonaWebsite[];
  pagination: PersonaPagination | null;
  hasMore: boolean;
  isLoading: boolean;
}

interface SearchState {
  query: string;
  domain: string;
  language: string;
}

const INIT_GALLERY = {
  websites: [],
  pagination: null,
  hasMore: true,
  isLoading: false,
};

const INIT_SEARCH = { query: '', domain: '', language: '' };

const SearchPage = () => {
  const navigate = useNavigate();
  const isLoadingRef = useRef<boolean>(false);
  const [search, setSearch] = useObjectQueryState<SearchState>(
    ['query', 'domain', 'language'],
    loadSearchState,
    serializeSearchState,
  );

  const [gallery, setGallery] = useState<GalleryState>(INIT_GALLERY);

  const fetchWebsites = useCallback(async (search: SearchState, gallery: GalleryState) => {
    const { pagination } = gallery;
    if (!search.query) return;
    setGallery((prevState) => ({ ...prevState, isLoading: true }));
    const page = pagination?.next || 1;
    try {
      const resp = await api.persona.searchQuery(
        search.query,
        page,
        PAGE_SIZE,
        search.domain,
        search.language,
      );
      const newVideos = resp.body?.websites || [];

      setGallery((prevState) => {
        const websiteSet = new Set(prevState.websites.map((w) => w.video_id));
        const uniqueNewWebsites = newVideos.filter((w) => !websiteSet.has(w.video_id));
        const websiteUpdate = [...prevState.websites, ...uniqueNewWebsites];
        const newState = {
          ...prevState,
          websites: websiteUpdate,
          pagination: resp.body?.pagination,
          hasMore: resp.body?.pagination?.next !== null,
        };
        return newState;
      });
    } catch (error) {
      console.error('Failed to fetch videos:', error);
    } finally {
      setGallery((prevState) => ({ ...prevState, isLoading: false }));
      isLoadingRef.current = false;
    }
  }, []);

  const onSearch = useCallback(() => {
    setGallery(INIT_GALLERY);
    fetchWebsites(search, INIT_GALLERY);
    navigate(`/search?query=${encodeURIComponent(search.query)}`);
  }, [navigate, setGallery, fetchWebsites, search]);

  const onClear = useCallback(() => {
    setSearch(INIT_SEARCH);
  }, [setSearch]);

  const onSuggestionClick = useCallback((suggestion: string) => {
    setSearch((prev) => ({ ...prev, query: suggestion }));
    setGallery(INIT_GALLERY);
    fetchWebsites({ query: suggestion, domain: '', language: '' }, INIT_GALLERY);
    navigate(`/search?query=${encodeURIComponent(suggestion)}`);
  }, [navigate, setGallery, setSearch, fetchWebsites]);

  useEffect(() => {
    if (search.query && !isLoadingRef.current) {
      isLoadingRef.current = true;
      fetchWebsites(search, gallery);
    }
  }, []);

  return (
    <Page>
      <CentredLayout>
        <ContentSearchBar
          onClear={onClear}
          onSearch={onSearch}
          onSuggestionClick={onSuggestionClick}
          setValue={(value) => setSearch((prev) => ({ ...prev, query: value }))}
          value={search.query}
        />
        <GalleryContainer>
          <InfiniteScroll
            style={{ overflowX: 'hidden', alignItems: 'center', justifyContent: 'center' }}
            dataLength={gallery.websites.length}
            next={() => fetchWebsites(search, gallery)}
            hasMore={gallery.hasMore && gallery.websites.length < 60}
            loader={<></>}
            scrollThreshold={0.2}
          >
            <ResponsiveMasonryStyled columnsCountBreakPoints={BREAKPOINTS}>
              <Masonry gutter="5px">
                {gallery.websites.map((website) => (
                  <Link
                    to={`/content/${encodeURIComponent(website.url)}?${new URLSearchParams({ query: encodeURIComponent(search.query) })}`}
                    state={{
                      content: null,
                      creatorName: website.creator_name,
                      contentTitle: website.title,
                      from: '/search' + window.location.search,
                    }}
                    key={website.video_id}
                  >
                    <CardFlip
                      imageSrc={website.thumbnail_url}
                      mainContent={website.title}
                      title={website.creator_name}
                      backContent={website.title}
                    />
                  </Link>
                ))}
              </Masonry>
            </ResponsiveMasonryStyled>
          </InfiniteScroll>
        </GalleryContainer>
      </CentredLayout>
    </Page>
  );
};

export default SearchPage;

const loadSearchState = (qs: QueryStringData): SearchState => {
  const loadedState = {
    domain: qs['domain'] ?? '',
    language: qs['language'] ?? '',
    query: qs['query'] ?? '',
  };
  const idQuery = qs['id'];
  if (idQuery !== null) {
    const parsed = parseInt(idQuery, 10);
    loadedState['id'] = isNaN(parsed) ? null : parsed;
  }
  return loadedState;
};

const serializeSearchState = (state: SearchState): QueryStringData => ({
  domain: state.domain ?? '',
  language: state.language ?? '',
  query: state.query ?? '',
});

const CentredLayout = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  justify-content: start;
  @media (max-width: 800px) {
    padding: 1em 0 0 0;
  }
`;

const GalleryContainer = styled.div`
  width: 100%;
`;

const ResponsiveMasonryStyled = styled(ResponsiveMasonry)`
  margin: 0 auto;
  @media (min-width: 1500px) {
    margin-right: 10%;
    margin-left: 10%;
  }
`;

const EndMessage = styled.h4`
  text-align: center;
  font-size: 1em;
  color: #000;
  opacity: 0.5;
  font-weight: normal;
`;

const BREAKPOINTS = {
  25: 1,
  200: 2,
  300: 3,
  328: 4,
  490: 4,
  675: 5,
  850: 6,
  1000: 6,
  1400: 6,
  1850: 6,
  2000: 6,
  2675: 9,
  3000: 12,
  3250: 13,
};
