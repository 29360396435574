import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api, { Video } from 'api';
import Gallery from './Gallery';
import ContentSearchBar from 'Components/ContentSearchBar';
import useContentSearch from 'Helpers/useContentSearch';

const VIDEOS_PER_PAGE = 60;

const generateThumbnailNavigationObject = (content: Video) => {
  const map = {
    youtube: {
      url: 'https://www.youtube.com/watch?v=' + content.video_id,
      name: content.channel_name,
      title: content.video_title,
    },
    tiktok: {
      url: content.video_url,
      name: content.channel_name,
      title: content.video_title,
    },
    xsocial: {
      url: content.post_url,
      name: content.channel_name,
      title: content.channel_nickname,
    },
    article: {
      url: content.video_id,
      name: content.channel_id,
      title: content.article_title,
    },
    iris_tv: {
      url: content.video_id,
      title: content.video_title,
    },
  };

  return {
    pathname: `/content/${encodeURIComponent(map[content.source].url)}`,
    state: {
      content,
      creatorName: map[content.source].name,
      contentTitle: map[content.source].title,
      from: window.location.pathname + window.location.search,
    },
  };
};

// @TODO: FIX NEEDED - This component renders twice when the page is loaded.
const HomeGallery = () => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState<Video[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [paginationKey, setPaginationKey] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const { onSearch, search, setSearch } = useContentSearch();

  const fetchRecentVideos = async (paginationKey = null) => {
    try {
      setIsLoading(true);
      const recentVideosResponse = await api.mbs.getRecentVideos(
        paginationKey,
        VIDEOS_PER_PAGE,
      );
      const newVideos = recentVideosResponse.body?.videos || [];
      setVideos((prevVideos) => {
        const videoSet = new Set(prevVideos.map((video) => video.video_id));
        const uniqueNewVideos = newVideos.filter((video) => !videoSet.has(video.video_id));
        return [...prevVideos, ...uniqueNewVideos];
      });
      setPaginationKey(recentVideosResponse.body.pagination_key);
      setHasMore(recentVideosResponse.body.pagination_key !== null);
    } catch (error) {
      console.error('Failed to fetch videos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCardClick = useCallback((content: Video) => {
    const { pathname, state } = generateThumbnailNavigationObject(content);
    const query = new URLSearchParams({ query: encodeURIComponent(search) });
    navigate(pathname + (query ? `?${query}` : ''), { state });
  }, [navigate, search]);

  useEffect(() => {
    fetchRecentVideos();
  }, []);

  useEffect(() => {
    if (!isInitialLoad) return;
    setIsInitialLoad(isLoading);
  }, [isLoading, isInitialLoad]);

  return (
    <>
      <ContentSearchBar
        onClear={() => setSearch('')}
        onSearch={onSearch}
        onSuggestionClick={onSearch}
        setValue={setSearch}
        value={search}
      />
      <Gallery
        fetchVideos={fetchRecentVideos}
        hasMore={hasMore}
        isInitialLoading={isInitialLoad}
        isLoading={isLoading}
        onCardClick={onCardClick}
        paginationKey={paginationKey}
        videos={videos}
        limit={120}
      />
    </>
  );
};

export default HomeGallery;
